<template>
    <div class="">
        <item-filter-link :filterLayout="filterLayout" :filterData="filterSet.filter"
                          :sortData="filterSet.sort"></item-filter-link>
        <item-empty-link :emptyLayout="emptyLayout" :finishGetList="finishGetList"
                         v-if="List.length === 0"></item-empty-link>
        <item-list-link :listLayout="listLayout" :itemData="List" :paginationData="filterSet.page"
                        v-else></item-list-link>
        <pagination-link :paginationLayout="paginationLayout" :paginationData="filterSet.page" :listLength="List.length"
                         :finishGetList="finishGetList"></pagination-link>
    </div>
</template>

<script>
import ItemListLink from "@/components/item/ItemListLink";
import ItemEmptyLink from "@/components/item/ItemEmptyLink";
import ItemFilterLink from "@/components/item/ItemFilterLink";
import PaginationLink from "@/components/item/PaginationLink";
import itemUtil from "@/components/item/itemUtil";
import listMixins from "@/mixins/listMixins";
import filterMixins from "@/mixins/filterMixins";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";
import {mapState} from "vuex";

const {filterConfig} = itemUtil;
let defaultFilterSet = {
    filter: {},
    page: {page: 1, size: 10, total: 0},
    sort: {},
}
export default {
    name: "ItemListEditionComponent",
    mixins: [itemUtil, alertMixins, filterMixins, listMixins],
    components: {
        ItemListLink,
        ItemEmptyLink,
        ItemFilterLink,
        PaginationLink,
    },
    inject: [],
    provide() {
        return {
            setPage: this.setPage,
            setFilter: this.setFilter,
            setSort: this.setSort,
            setItemListData: this.setData,
        }
    },
    props: {
        itemType: '',
        itemKey: '',
    },
    data() {
        return {
            listLayout: '',
            filterLayout: '',
            emptyLayout: '',
            paginationLayout: '',
            getFunc: '',
            listApiParamSet: {},
            keyName: '',
            pageRow: '',
            paginationType: '',

        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
        this.setFilterConfig(filterConfig)
        this.setParserType('vuex', defaultFilterSet);
    },
    mounted() {
        this.getList(true);
        EventBus.$on('getItemList', this.getList);
        EventBus.$on('editionSort', this.editionSort);
    },
    beforeDestroy() {
        EventBus.$off('getItemList');
        EventBus.$off('editionSort');

    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        initConfig() {
            let itemConfig = this.returnItemTemplateConfig(this.itemType);
            this.listLayout = itemConfig.listLayout;
            this.filterLayout = itemConfig.filterLayout;
            this.emptyLayout = itemConfig.emptyLayout;
            this.paginationLayout = itemConfig.paginationLayout;
            this.getFunc = itemConfig.getFunc;
            this.listApiParamSet = itemConfig.listApiParamSet;
            this.keyName = itemConfig.keyName;
            this.pageRow = itemConfig.pageRow
            this.paginationType = itemConfig.paginationType;

            if (itemConfig.pageRow && itemConfig.pageRow != this.filterSet.page.size) {
                this.pageRow = itemConfig.pageRow;
                this.filterSet.page.size = itemConfig.pageRow;
                defaultFilterSet.page.size = itemConfig.pageRow;
                this.pageClass.setterPage('size', this.pageRow);
            }

            let params = JSON.parse(JSON.stringify(this.listApiParamSet));

            if ((this.itemKey !== '' || !util.isEmpty(this.itemKey)) && !util.isEmpty(this.keyName)) {
                params[this.keyName] = this.itemKey
            }

            let listOptions = {
                url: this.$api.$item[this.getFunc],
                defaultParams: params,
            }
            this.initList('api', listOptions);
        },
        editionSort(type, key) {
            if (type === 'number') {
                this.List = this.$_.orderBy(this.List, ['im_count'], [key])
            } else if (type === 'type') {
                this.List = this.$_.orderBy(this.List, ['im_status'], [key])
            } else if (type === 'price') {
                this.List = this.$_.orderBy(this.List, ['i_price'], [key])
            }
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
